export const ReportingViewFormSettingsFormModal = () => import('../../client/components/account/admin/Reporting/View/Form/Settings/ReportingViewFormSettingsFormModal.vue' /* webpackChunkName: "components/reporting-view-form-settings-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormSettings = () => import('../../client/components/account/admin/Reporting/View/Form/Settings/index.vue' /* webpackChunkName: "components/reporting-form-settings" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormSettingsUtils = () => import('../../client/components/account/admin/Reporting/View/Form/Settings/utils.js' /* webpackChunkName: "components/reporting-form-settings-utils" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormReportFormInput = () => import('../../client/components/account/admin/Reporting/View/Form/ReportFormInput.vue' /* webpackChunkName: "components/reporting-form-report-form-input" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormReportFormModal = () => import('../../client/components/account/admin/Reporting/View/Form/ReportFormModal.vue' /* webpackChunkName: "components/reporting-form-report-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormFiltersReportFilterFormModal = () => import('../../client/components/account/admin/Reporting/View/Form/Filters/ReportFilterFormModal.vue' /* webpackChunkName: "components/reporting-form-filters-report-filter-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportingFormFilters = () => import('../../client/components/account/admin/Reporting/View/Form/Filters/index.vue' /* webpackChunkName: "components/reporting-form-filters" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductConfigureProduct1 = () => import('../../client/components/configureproduct/ConfigureProduct1.vue' /* webpackChunkName: "components/configureproduct-configure-product1" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductDesignModal = () => import('../../client/components/configureproduct/DesignModal.vue' /* webpackChunkName: "components/configureproduct-design-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductFormConfigure = () => import('../../client/components/configureproduct/FormConfigure.vue' /* webpackChunkName: "components/configureproduct-form-configure" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductFormConfigureColor = () => import('../../client/components/configureproduct/FormConfigureColor.vue' /* webpackChunkName: "components/configureproduct-form-configure-color" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductFormConfigureSizes = () => import('../../client/components/configureproduct/FormConfigureSizes.vue' /* webpackChunkName: "components/configureproduct-form-configure-sizes" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductPreloader = () => import('../../client/components/configureproduct/Preloader.vue' /* webpackChunkName: "components/configureproduct-preloader" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductColor = () => import('../../client/components/configureproduct/ProductColor.vue' /* webpackChunkName: "components/configureproduct-product-color" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDecoration = () => import('../../client/components/configureproduct/ProductDecoration.vue' /* webpackChunkName: "components/configureproduct-product-decoration" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDefaultDecoration = () => import('../../client/components/configureproduct/ProductDefaultDecoration.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDefaultDecorationDropdown = () => import('../../client/components/configureproduct/ProductDefaultDecorationDropdown.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDefaultDecorationDropdownOption = () => import('../../client/components/configureproduct/ProductDefaultDecorationDropdownOption.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-dropdown-option" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDefaultDecorationLocationDropdown = () => import('../../client/components/configureproduct/ProductDefaultDecorationLocationDropdown.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-location-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDefaultDecorationV1 = () => import('../../client/components/configureproduct/ProductDefaultDecorationV1.vue' /* webpackChunkName: "components/configureproduct-product-default-decoration-v1" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductDesign = () => import('../../client/components/configureproduct/ProductDesign.vue' /* webpackChunkName: "components/configureproduct-product-design" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductQuantityTable = () => import('../../client/components/configureproduct/ProductQuantityTable.vue' /* webpackChunkName: "components/configureproduct-product-quantity-table" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductProductTotal = () => import('../../client/components/configureproduct/ProductTotal.vue' /* webpackChunkName: "components/configureproduct-product-total" */).then(c => wrapFunctional(c.default || c))
export const ConfigureproductTypesetSection = () => import('../../client/components/configureproduct/TypesetSection.vue' /* webpackChunkName: "components/configureproduct-typeset-section" */).then(c => wrapFunctional(c.default || c))
export const AddressFields = () => import('../../client/components/AddressFields.vue' /* webpackChunkName: "components/address-fields" */).then(c => wrapFunctional(c.default || c))
export const AddressInformation = () => import('../../client/components/AddressInformation.vue' /* webpackChunkName: "components/address-information" */).then(c => wrapFunctional(c.default || c))
export const BDropdownHover = () => import('../../client/components/BDropdownHover.vue' /* webpackChunkName: "components/b-dropdown-hover" */).then(c => wrapFunctional(c.default || c))
export const ButtonInventoryNext = () => import('../../client/components/ButtonInventoryNext.vue' /* webpackChunkName: "components/button-inventory-next" */).then(c => wrapFunctional(c.default || c))
export const CartItemInfo = () => import('../../client/components/CartItemInfo.vue' /* webpackChunkName: "components/cart-item-info" */).then(c => wrapFunctional(c.default || c))
export const CartItems = () => import('../../client/components/CartItems.vue' /* webpackChunkName: "components/cart-items" */).then(c => wrapFunctional(c.default || c))
export const CartItemsViewPartPrice = () => import('../../client/components/CartItemsViewPartPrice.vue' /* webpackChunkName: "components/cart-items-view-part-price" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductsList = () => import('../../client/components/CategoryProductsList.vue' /* webpackChunkName: "components/category-products-list" */).then(c => wrapFunctional(c.default || c))
export const CountUpCounter = () => import('../../client/components/CountUpCounter.vue' /* webpackChunkName: "components/count-up-counter" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../client/components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const CustomerCapture = () => import('../../client/components/CustomerCapture.vue' /* webpackChunkName: "components/customer-capture" */).then(c => wrapFunctional(c.default || c))
export const CustomerInformation = () => import('../../client/components/CustomerInformation.vue' /* webpackChunkName: "components/customer-information" */).then(c => wrapFunctional(c.default || c))
export const CustomerInvoice = () => import('../../client/components/CustomerInvoice.vue' /* webpackChunkName: "components/customer-invoice" */).then(c => wrapFunctional(c.default || c))
export const CustomerInvoicePrint = () => import('../../client/components/CustomerInvoicePrint.vue' /* webpackChunkName: "components/customer-invoice-print" */).then(c => wrapFunctional(c.default || c))
export const DeleteButton = () => import('../../client/components/DeleteButton.vue' /* webpackChunkName: "components/delete-button" */).then(c => wrapFunctional(c.default || c))
export const Designs = () => import('../../client/components/Designs.vue' /* webpackChunkName: "components/designs" */).then(c => wrapFunctional(c.default || c))
export const FacetsList = () => import('../../client/components/FacetsList.vue' /* webpackChunkName: "components/facets-list" */).then(c => wrapFunctional(c.default || c))
export const FacetsProductGrid = () => import('../../client/components/FacetsProductGrid.vue' /* webpackChunkName: "components/facets-product-grid" */).then(c => wrapFunctional(c.default || c))
export const FavoritesModal = () => import('../../client/components/FavoritesModal.vue' /* webpackChunkName: "components/favorites-modal" */).then(c => wrapFunctional(c.default || c))
export const FilesPreview = () => import('../../client/components/FilesPreview.vue' /* webpackChunkName: "components/files-preview" */).then(c => wrapFunctional(c.default || c))
export const GoogleAutocomplete = () => import('../../client/components/GoogleAutocomplete.vue' /* webpackChunkName: "components/google-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const IEWarning = () => import('../../client/components/IEWarning.vue' /* webpackChunkName: "components/i-e-warning" */).then(c => wrapFunctional(c.default || c))
export const ListPagesByCategory = () => import('../../client/components/ListPagesByCategory.vue' /* webpackChunkName: "components/list-pages-by-category" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../client/components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoadingPage = () => import('../../client/components/LoadingPage.vue' /* webpackChunkName: "components/loading-page" */).then(c => wrapFunctional(c.default || c))
export const MPowerAutocomplete = () => import('../../client/components/MPowerAutocomplete.vue' /* webpackChunkName: "components/m-power-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const MPowerDesigner = () => import('../../client/components/MPowerDesigner.vue' /* webpackChunkName: "components/m-power-designer" */).then(c => wrapFunctional(c.default || c))
export const MPowerImg = () => import('../../client/components/MPowerImg.vue' /* webpackChunkName: "components/m-power-img" */).then(c => wrapFunctional(c.default || c))
export const MPowerLightBox = () => import('../../client/components/MPowerLightBox.vue' /* webpackChunkName: "components/m-power-light-box" */).then(c => wrapFunctional(c.default || c))
export const MPowerMultiSelect = () => import('../../client/components/MPowerMultiSelect.vue' /* webpackChunkName: "components/m-power-multi-select" */).then(c => wrapFunctional(c.default || c))
export const MPowerQuantityTable = () => import('../../client/components/MPowerQuantityTable.vue' /* webpackChunkName: "components/m-power-quantity-table" */).then(c => wrapFunctional(c.default || c))
export const MPowerTable = () => import('../../client/components/MPowerTable.vue' /* webpackChunkName: "components/m-power-table" */).then(c => wrapFunctional(c.default || c))
export const MPowerTableClient = () => import('../../client/components/MPowerTableClient.vue' /* webpackChunkName: "components/m-power-table-client" */).then(c => wrapFunctional(c.default || c))
export const MPowerTableClientWrapper = () => import('../../client/components/MPowerTableClientWrapper.vue' /* webpackChunkName: "components/m-power-table-client-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MPowerTableWrapper = () => import('../../client/components/MPowerTableWrapper.vue' /* webpackChunkName: "components/m-power-table-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MPowerValidateInput = () => import('../../client/components/MPowerValidateInput.vue' /* webpackChunkName: "components/m-power-validate-input" */).then(c => wrapFunctional(c.default || c))
export const MPowerValidateInputWrapper = () => import('../../client/components/MPowerValidateInputWrapper.vue' /* webpackChunkName: "components/m-power-validate-input-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MPowerValidatedInput = () => import('../../client/components/MPowerValidatedInput.vue' /* webpackChunkName: "components/m-power-validated-input" */).then(c => wrapFunctional(c.default || c))
export const NuxtDynamic = () => import('../../client/components/NuxtDynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => wrapFunctional(c.default || c))
export const NuxtImg = () => import('../../client/components/NuxtImg.vue' /* webpackChunkName: "components/nuxt-img" */).then(c => wrapFunctional(c.default || c))
export const OrderInfo = () => import('../../client/components/OrderInfo.vue' /* webpackChunkName: "components/order-info" */).then(c => wrapFunctional(c.default || c))
export const OrderInfoShipment = () => import('../../client/components/OrderInfoShipment.vue' /* webpackChunkName: "components/order-info-shipment" */).then(c => wrapFunctional(c.default || c))
export const PasswordInput = () => import('../../client/components/PasswordInput.vue' /* webpackChunkName: "components/password-input" */).then(c => wrapFunctional(c.default || c))
export const ProductRestrictionCodeInput = () => import('../../client/components/ProductRestrictionCodeInput.vue' /* webpackChunkName: "components/product-restriction-code-input" */).then(c => wrapFunctional(c.default || c))
export const ProductSpecialBanner = () => import('../../client/components/ProductSpecialBanner.vue' /* webpackChunkName: "components/product-special-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductsGrid = () => import('../../client/components/ProductsGrid.vue' /* webpackChunkName: "components/products-grid" */).then(c => wrapFunctional(c.default || c))
export const ProductsGridTemplate = () => import('../../client/components/ProductsGridTemplate.vue' /* webpackChunkName: "components/products-grid-template" */).then(c => wrapFunctional(c.default || c))
export const ReorderBtn = () => import('../../client/components/ReorderBtn.vue' /* webpackChunkName: "components/reorder-btn" */).then(c => wrapFunctional(c.default || c))
export const SelectedDesignCard = () => import('../../client/components/SelectedDesignCard.vue' /* webpackChunkName: "components/selected-design-card" */).then(c => wrapFunctional(c.default || c))
export const ShippingFixModal = () => import('../../client/components/ShippingFixModal.vue' /* webpackChunkName: "components/shipping-fix-modal" */).then(c => wrapFunctional(c.default || c))
export const SocialShare = () => import('../../client/components/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c))
export const StoreDesigns = () => import('../../client/components/StoreDesigns.vue' /* webpackChunkName: "components/store-designs" */).then(c => wrapFunctional(c.default || c))
export const StorePageCard = () => import('../../client/components/StorePageCard.vue' /* webpackChunkName: "components/store-page-card" */).then(c => wrapFunctional(c.default || c))
export const SuppliersList = () => import('../../client/components/SuppliersList.vue' /* webpackChunkName: "components/suppliers-list" */).then(c => wrapFunctional(c.default || c))
export const TemplateByCategory = () => import('../../client/components/TemplateByCategory.vue' /* webpackChunkName: "components/template-by-category" */).then(c => wrapFunctional(c.default || c))
export const Templates = () => import('../../client/components/Templates.vue' /* webpackChunkName: "components/templates" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../client/components/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const VueCsvImport = () => import('../../client/components/VueCsvImport.vue' /* webpackChunkName: "components/vue-csv-import" */).then(c => wrapFunctional(c.default || c))
export const Noscript = () => import('../../client/components/noscript.vue' /* webpackChunkName: "components/noscript" */).then(c => wrapFunctional(c.default || c))
export const PageGenerator = () => import('../../client/components/pageGenerator.vue' /* webpackChunkName: "components/page-generator" */).then(c => wrapFunctional(c.default || c))
export const Item = () => import('../../client/components/Comments/Item.vue' /* webpackChunkName: "components/item" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../client/components/Comments/index.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const Addresses = () => import('../../client/components/account/Addresses.vue' /* webpackChunkName: "components/addresses" */).then(c => wrapFunctional(c.default || c))
export const CreateAccount = () => import('../../client/components/account/CreateAccount.vue' /* webpackChunkName: "components/create-account" */).then(c => wrapFunctional(c.default || c))
export const MyAccount1 = () => import('../../client/components/account/MyAccount1.vue' /* webpackChunkName: "components/my-account1" */).then(c => wrapFunctional(c.default || c))
export const Orders = () => import('../../client/components/account/Orders.vue' /* webpackChunkName: "components/orders" */).then(c => wrapFunctional(c.default || c))
export const Presentations = () => import('../../client/components/account/Presentations.vue' /* webpackChunkName: "components/presentations" */).then(c => wrapFunctional(c.default || c))
export const AccountManager1 = () => import('../../client/components/accountmanager/AccountManager1.vue' /* webpackChunkName: "components/account-manager1" */).then(c => wrapFunctional(c.default || c))
export const VirtualArtproof1 = () => import('../../client/components/artproof/VirtualArtproof1.vue' /* webpackChunkName: "components/virtual-artproof1" */).then(c => wrapFunctional(c.default || c))
export const Button1 = () => import('../../client/components/basic/Button1.vue' /* webpackChunkName: "components/button1" */).then(c => wrapFunctional(c.default || c))
export const Confirmation1 = () => import('../../client/components/basic/Confirmation1.vue' /* webpackChunkName: "components/confirmation1" */).then(c => wrapFunctional(c.default || c))
export const DesignBlock1 = () => import('../../client/components/basic/DesignBlock1.vue' /* webpackChunkName: "components/design-block1" */).then(c => wrapFunctional(c.default || c))
export const Heading1 = () => import('../../client/components/basic/Heading1.vue' /* webpackChunkName: "components/heading1" */).then(c => wrapFunctional(c.default || c))
export const Heading2 = () => import('../../client/components/basic/Heading2.vue' /* webpackChunkName: "components/heading2" */).then(c => wrapFunctional(c.default || c))
export const Heading3 = () => import('../../client/components/basic/Heading3.vue' /* webpackChunkName: "components/heading3" */).then(c => wrapFunctional(c.default || c))
export const Html1 = () => import('../../client/components/basic/Html1.vue' /* webpackChunkName: "components/html1" */).then(c => wrapFunctional(c.default || c))
export const Image1 = () => import('../../client/components/basic/Image1.vue' /* webpackChunkName: "components/image1" */).then(c => wrapFunctional(c.default || c))
export const Link1 = () => import('../../client/components/basic/Link1.vue' /* webpackChunkName: "components/link1" */).then(c => wrapFunctional(c.default || c))
export const ListOrdered1 = () => import('../../client/components/basic/ListOrdered1.vue' /* webpackChunkName: "components/list-ordered1" */).then(c => wrapFunctional(c.default || c))
export const ListUnordered1 = () => import('../../client/components/basic/ListUnordered1.vue' /* webpackChunkName: "components/list-unordered1" */).then(c => wrapFunctional(c.default || c))
export const Text1 = () => import('../../client/components/basic/Text1.vue' /* webpackChunkName: "components/text1" */).then(c => wrapFunctional(c.default || c))
export const BlogList1 = () => import('../../client/components/blog/BlogList1.vue' /* webpackChunkName: "components/blog-list1" */).then(c => wrapFunctional(c.default || c))
export const BlogPost1 = () => import('../../client/components/blog/BlogPost1.vue' /* webpackChunkName: "components/blog-post1" */).then(c => wrapFunctional(c.default || c))
export const BlogPostBreadcrumbs = () => import('../../client/components/blog/BlogPostBreadcrumbs.vue' /* webpackChunkName: "components/blog-post-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BlogPostProducts1 = () => import('../../client/components/blog/BlogPostProducts1.vue' /* webpackChunkName: "components/blog-post-products1" */).then(c => wrapFunctional(c.default || c))
export const BlogPostsList1 = () => import('../../client/components/blog/BlogPostsList1.vue' /* webpackChunkName: "components/blog-posts-list1" */).then(c => wrapFunctional(c.default || c))
export const SavedBoxItem = () => import('../../client/components/boxItems/SavedBoxItem.vue' /* webpackChunkName: "components/saved-box-item" */).then(c => wrapFunctional(c.default || c))
export const UnsavedBoxItem = () => import('../../client/components/boxItems/UnsavedBoxItem.vue' /* webpackChunkName: "components/unsaved-box-item" */).then(c => wrapFunctional(c.default || c))
export const BoxItems = () => import('../../client/components/boxItems/index.vue' /* webpackChunkName: "components/box-items" */).then(c => wrapFunctional(c.default || c))
export const Carousel1 = () => import('../../client/components/carousel/Carousel1.vue' /* webpackChunkName: "components/carousel1" */).then(c => wrapFunctional(c.default || c))
export const Carousel2 = () => import('../../client/components/carousel/Carousel2.vue' /* webpackChunkName: "components/carousel2" */).then(c => wrapFunctional(c.default || c))
export const Carousel3 = () => import('../../client/components/carousel/Carousel3.vue' /* webpackChunkName: "components/carousel3" */).then(c => wrapFunctional(c.default || c))
export const Cart1 = () => import('../../client/components/cart/Cart1.vue' /* webpackChunkName: "components/cart1" */).then(c => wrapFunctional(c.default || c))
export const CartItemsAdded = () => import('../../client/components/cartitemsadded/CartItemsAdded.vue' /* webpackChunkName: "components/cart-items-added" */).then(c => wrapFunctional(c.default || c))
export const CartItemsAdded1 = () => import('../../client/components/cartitemsadded/CartItemsAdded1.vue' /* webpackChunkName: "components/cart-items-added1" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountForm = () => import('../../client/components/cartitemsadded/CreateAccountForm.vue' /* webpackChunkName: "components/create-account-form" */).then(c => wrapFunctional(c.default || c))
export const SignInForm = () => import('../../client/components/cartitemsadded/SignInForm.vue' /* webpackChunkName: "components/sign-in-form" */).then(c => wrapFunctional(c.default || c))
export const Categories1 = () => import('../../client/components/categories/Categories1.vue' /* webpackChunkName: "components/categories1" */).then(c => wrapFunctional(c.default || c))
export const CategoriesList1 = () => import('../../client/components/categories/CategoriesList1.vue' /* webpackChunkName: "components/categories-list1" */).then(c => wrapFunctional(c.default || c))
export const CategoryBreadcrumb1 = () => import('../../client/components/categories/CategoryBreadcrumb1.vue' /* webpackChunkName: "components/category-breadcrumb1" */).then(c => wrapFunctional(c.default || c))
export const ChangePassword1 = () => import('../../client/components/changepassword/ChangePassword1.vue' /* webpackChunkName: "components/change-password1" */).then(c => wrapFunctional(c.default || c))
export const Checkout1 = () => import('../../client/components/checkout/Checkout1.vue' /* webpackChunkName: "components/checkout1" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddress = () => import('../../client/components/checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c))
export const CreditAllowance = () => import('../../client/components/checkout/CreditAllowance.vue' /* webpackChunkName: "components/credit-allowance" */).then(c => wrapFunctional(c.default || c))
export const DiscountCode = () => import('../../client/components/checkout/DiscountCode.vue' /* webpackChunkName: "components/discount-code" */).then(c => wrapFunctional(c.default || c))
export const DocumentPayment = () => import('../../client/components/checkout/DocumentPayment.vue' /* webpackChunkName: "components/document-payment" */).then(c => wrapFunctional(c.default || c))
export const ShipmentAddress = () => import('../../client/components/checkout/ShipmentAddress.vue' /* webpackChunkName: "components/shipment-address" */).then(c => wrapFunctional(c.default || c))
export const SubmitToJagger = () => import('../../client/components/checkout/SubmitToJagger.vue' /* webpackChunkName: "components/submit-to-jagger" */).then(c => wrapFunctional(c.default || c))
export const ColorConverter1 = () => import('../../client/components/colorTools/ColorConverter1.vue' /* webpackChunkName: "components/color-converter1" */).then(c => wrapFunctional(c.default || c))
export const ImageColorPalette1 = () => import('../../client/components/colorTools/ImageColorPalette1.vue' /* webpackChunkName: "components/image-color-palette1" */).then(c => wrapFunctional(c.default || c))
export const ContactForm1 = () => import('../../client/components/contactForm/ContactForm1.vue' /* webpackChunkName: "components/contact-form1" */).then(c => wrapFunctional(c.default || c))
export const DesignCustomerArtworks = () => import('../../client/components/designer/DesignCustomerArtworks.vue' /* webpackChunkName: "components/design-customer-artworks" */).then(c => wrapFunctional(c.default || c))
export const DesignCustomerArtworksGrid = () => import('../../client/components/designer/DesignCustomerArtworksGrid.vue' /* webpackChunkName: "components/design-customer-artworks-grid" */).then(c => wrapFunctional(c.default || c))
export const DesignImages = () => import('../../client/components/designer/DesignImages.vue' /* webpackChunkName: "components/design-images" */).then(c => wrapFunctional(c.default || c))
export const DesignProduct1 = () => import('../../client/components/designer/DesignProduct1.vue' /* webpackChunkName: "components/design-product1" */).then(c => wrapFunctional(c.default || c))
export const Error1 = () => import('../../client/components/error/Error1.vue' /* webpackChunkName: "components/error1" */).then(c => wrapFunctional(c.default || c))
export const Facets1 = () => import('../../client/components/facets/Facets1.vue' /* webpackChunkName: "components/facets1" */).then(c => wrapFunctional(c.default || c))
export const Favorites1 = () => import('../../client/components/favorites/Favorites1.vue' /* webpackChunkName: "components/favorites1" */).then(c => wrapFunctional(c.default || c))
export const CategoriesFilter = () => import('../../client/components/filters/CategoriesFilter.vue' /* webpackChunkName: "components/categories-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoriesFilterItem = () => import('../../client/components/filters/CategoriesFilterItem.vue' /* webpackChunkName: "components/categories-filter-item" */).then(c => wrapFunctional(c.default || c))
export const CategoriesFilterNestedItem = () => import('../../client/components/filters/CategoriesFilterNestedItem.vue' /* webpackChunkName: "components/categories-filter-nested-item" */).then(c => wrapFunctional(c.default || c))
export const CategoryChildsFilter = () => import('../../client/components/filters/CategoryChildsFilter.vue' /* webpackChunkName: "components/category-childs-filter" */).then(c => wrapFunctional(c.default || c))
export const FacetsFilter = () => import('../../client/components/filters/FacetsFilter.vue' /* webpackChunkName: "components/facets-filter" */).then(c => wrapFunctional(c.default || c))
export const InventoryFilter = () => import('../../client/components/filters/InventoryFilter.vue' /* webpackChunkName: "components/inventory-filter" */).then(c => wrapFunctional(c.default || c))
export const PriceFilter = () => import('../../client/components/filters/PriceFilter.vue' /* webpackChunkName: "components/price-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../client/components/filters/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductListDetails = () => import('../../client/components/filters/ProductListDetails.vue' /* webpackChunkName: "components/product-list-details" */).then(c => wrapFunctional(c.default || c))
export const ProductsFilters = () => import('../../client/components/filters/ProductsFilters.vue' /* webpackChunkName: "components/products-filters" */).then(c => wrapFunctional(c.default || c))
export const SearchFilter = () => import('../../client/components/filters/SearchFilter.vue' /* webpackChunkName: "components/search-filter" */).then(c => wrapFunctional(c.default || c))
export const SelectedFilters = () => import('../../client/components/filters/SelectedFilters.vue' /* webpackChunkName: "components/selected-filters" */).then(c => wrapFunctional(c.default || c))
export const SortProductList = () => import('../../client/components/filters/SortProductList.vue' /* webpackChunkName: "components/sort-product-list" */).then(c => wrapFunctional(c.default || c))
export const SortProducts = () => import('../../client/components/filters/SortProducts.vue' /* webpackChunkName: "components/sort-products" */).then(c => wrapFunctional(c.default || c))
export const SupplierFilter = () => import('../../client/components/filters/SupplierFilter.vue' /* webpackChunkName: "components/supplier-filter" */).then(c => wrapFunctional(c.default || c))
export const Flex1 = () => import('../../client/components/flex/Flex1.vue' /* webpackChunkName: "components/flex1" */).then(c => wrapFunctional(c.default || c))
export const Footer1 = () => import('../../client/components/footer/Footer1.vue' /* webpackChunkName: "components/footer1" */).then(c => wrapFunctional(c.default || c))
export const ForgotPassword1 = () => import('../../client/components/forgotpassword/ForgotPassword1.vue' /* webpackChunkName: "components/forgot-password1" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSignup1 = () => import('../../client/components/forms/NewsletterSignup1.vue' /* webpackChunkName: "components/newsletter-signup1" */).then(c => wrapFunctional(c.default || c))
export const Grid1 = () => import('../../client/components/grid/Grid1.vue' /* webpackChunkName: "components/grid1" */).then(c => wrapFunctional(c.default || c))
export const Grid2 = () => import('../../client/components/grid/Grid2.vue' /* webpackChunkName: "components/grid2" */).then(c => wrapFunctional(c.default || c))
export const InfiniteScroll1 = () => import('../../client/components/infinitescroll/InfiniteScroll1.vue' /* webpackChunkName: "components/infinite-scroll1" */).then(c => wrapFunctional(c.default || c))
export const InfoBar1 = () => import('../../client/components/infobar/InfoBar1.vue' /* webpackChunkName: "components/info-bar1" */).then(c => wrapFunctional(c.default || c))
export const InstagramFeed1 = () => import('../../client/components/instagram/InstagramFeed1.vue' /* webpackChunkName: "components/instagram-feed1" */).then(c => wrapFunctional(c.default || c))
export const Jumbotron1 = () => import('../../client/components/jumbotron/Jumbotron1.vue' /* webpackChunkName: "components/jumbotron1" */).then(c => wrapFunctional(c.default || c))
export const Jumbotron2 = () => import('../../client/components/jumbotron/Jumbotron2.vue' /* webpackChunkName: "components/jumbotron2" */).then(c => wrapFunctional(c.default || c))
export const Megamenu1 = () => import('../../client/components/megamenu/Megamenu1.vue' /* webpackChunkName: "components/megamenu1" */).then(c => wrapFunctional(c.default || c))
export const Megamenu2 = () => import('../../client/components/megamenu/Megamenu2.vue' /* webpackChunkName: "components/megamenu2" */).then(c => wrapFunctional(c.default || c))
export const FiltersModal = () => import('../../client/components/modals/FiltersModal.vue' /* webpackChunkName: "components/filters-modal" */).then(c => wrapFunctional(c.default || c))
export const Modal1 = () => import('../../client/components/modals/Modal1.vue' /* webpackChunkName: "components/modal1" */).then(c => wrapFunctional(c.default || c))
export const CategoriesMenuItems = () => import('../../client/components/navbar/CategoriesMenuItems.vue' /* webpackChunkName: "components/categories-menu-items" */).then(c => wrapFunctional(c.default || c))
export const NavLink = () => import('../../client/components/navbar/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c))
export const Navbar1 = () => import('../../client/components/navbar/Navbar1.vue' /* webpackChunkName: "components/navbar1" */).then(c => wrapFunctional(c.default || c))
export const Navbar2 = () => import('../../client/components/navbar/Navbar2.vue' /* webpackChunkName: "components/navbar2" */).then(c => wrapFunctional(c.default || c))
export const OrderApproval1 = () => import('../../client/components/orderApproval/OrderApproval1.vue' /* webpackChunkName: "components/order-approval1" */).then(c => wrapFunctional(c.default || c))
export const OrderPrintView = () => import('../../client/components/orderView/OrderPrintView.vue' /* webpackChunkName: "components/order-print-view" */).then(c => wrapFunctional(c.default || c))
export const OrderView1 = () => import('../../client/components/orderView/OrderView1.vue' /* webpackChunkName: "components/order-view1" */).then(c => wrapFunctional(c.default || c))
export const LastOrdersProductsList1 = () => import('../../client/components/ordersProducts/LastOrdersProductsList1.vue' /* webpackChunkName: "components/last-orders-products-list1" */).then(c => wrapFunctional(c.default || c))
export const OrdersProducts = () => import('../../client/components/ordersProducts/OrdersProducts.vue' /* webpackChunkName: "components/orders-products" */).then(c => wrapFunctional(c.default || c))
export const OrdersProductsList1 = () => import('../../client/components/ordersProducts/OrdersProductsList1.vue' /* webpackChunkName: "components/orders-products-list1" */).then(c => wrapFunctional(c.default || c))
export const Payments1 = () => import('../../client/components/payments/Payments1.vue' /* webpackChunkName: "components/payments1" */).then(c => wrapFunctional(c.default || c))
export const PaymentsList = () => import('../../client/components/payments/PaymentsList.vue' /* webpackChunkName: "components/payments-list" */).then(c => wrapFunctional(c.default || c))
export const Presentation1 = () => import('../../client/components/presentation/Presentation1.vue' /* webpackChunkName: "components/presentation1" */).then(c => wrapFunctional(c.default || c))
export const PresentationOption = () => import('../../client/components/presentation/PresentationOption.vue' /* webpackChunkName: "components/presentation-option" */).then(c => wrapFunctional(c.default || c))
export const PresentationOptionControls = () => import('../../client/components/presentation/PresentationOptionControls.vue' /* webpackChunkName: "components/presentation-option-controls" */).then(c => wrapFunctional(c.default || c))
export const PresentationOptionCustomPO = () => import('../../client/components/presentation/PresentationOptionCustomPO.vue' /* webpackChunkName: "components/presentation-option-custom-p-o" */).then(c => wrapFunctional(c.default || c))
export const PresentationOptionPO = () => import('../../client/components/presentation/PresentationOptionPO.vue' /* webpackChunkName: "components/presentation-option-p-o" */).then(c => wrapFunctional(c.default || c))
export const PresentationView = () => import('../../client/components/presentations/PresentationView.vue' /* webpackChunkName: "components/presentation-view" */).then(c => wrapFunctional(c.default || c))
export const PresentationView1 = () => import('../../client/components/presentations/PresentationView1.vue' /* webpackChunkName: "components/presentation-view1" */).then(c => wrapFunctional(c.default || c))
export const ProductCardButton = () => import('../../client/components/productCard/ProductCardButton.vue' /* webpackChunkName: "components/product-card-button" */).then(c => wrapFunctional(c.default || c))
export const ProductCardButtonsList = () => import('../../client/components/productCard/ProductCardButtonsList.vue' /* webpackChunkName: "components/product-card-buttons-list" */).then(c => wrapFunctional(c.default || c))
export const ProductCardInfo = () => import('../../client/components/productCard/ProductCardInfo.vue' /* webpackChunkName: "components/product-card-info" */).then(c => wrapFunctional(c.default || c))
export const ButtonsSection = () => import('../../client/components/productView/ButtonsSection.vue' /* webpackChunkName: "components/buttons-section" */).then(c => wrapFunctional(c.default || c))
export const DocumentsSection = () => import('../../client/components/productView/DocumentsSection.vue' /* webpackChunkName: "components/documents-section" */).then(c => wrapFunctional(c.default || c))
export const InventoryStatus = () => import('../../client/components/productView/InventoryStatus.vue' /* webpackChunkName: "components/inventory-status" */).then(c => wrapFunctional(c.default || c))
export const IsCloseout = () => import('../../client/components/productView/IsCloseout.vue' /* webpackChunkName: "components/is-closeout" */).then(c => wrapFunctional(c.default || c))
export const PartsColor = () => import('../../client/components/productView/PartsColor.vue' /* webpackChunkName: "components/parts-color" */).then(c => wrapFunctional(c.default || c))
export const PartsColors = () => import('../../client/components/productView/PartsColors.vue' /* webpackChunkName: "components/parts-colors" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../client/components/productView/ProductAttributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductDecoration = () => import('../../client/components/productView/ProductDecoration.vue' /* webpackChunkName: "components/product-decoration" */).then(c => wrapFunctional(c.default || c))
export const ProductDecorationPartsSelect = () => import('../../client/components/productView/ProductDecorationPartsSelect.vue' /* webpackChunkName: "components/product-decoration-parts-select" */).then(c => wrapFunctional(c.default || c))
export const ProductDecorationSelect = () => import('../../client/components/productView/ProductDecorationSelect.vue' /* webpackChunkName: "components/product-decoration-select" */).then(c => wrapFunctional(c.default || c))
export const ProductDescriptions = () => import('../../client/components/productView/ProductDescriptions.vue' /* webpackChunkName: "components/product-descriptions" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatures = () => import('../../client/components/productView/ProductFeatures.vue' /* webpackChunkName: "components/product-features" */).then(c => wrapFunctional(c.default || c))
export const ProductFomDiscontinued = () => import('../../client/components/productView/ProductFomDiscontinued.vue' /* webpackChunkName: "components/product-fom-discontinued" */).then(c => wrapFunctional(c.default || c))
export const ProductHeader = () => import('../../client/components/productView/ProductHeader.vue' /* webpackChunkName: "components/product-header" */).then(c => wrapFunctional(c.default || c))
export const ProductHeaderDetails = () => import('../../client/components/productView/ProductHeaderDetails.vue' /* webpackChunkName: "components/product-header-details" */).then(c => wrapFunctional(c.default || c))
export const ProductImage = () => import('../../client/components/productView/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductLastReviews = () => import('../../client/components/productView/ProductLastReviews.vue' /* webpackChunkName: "components/product-last-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductLocationDecorations = () => import('../../client/components/productView/ProductLocationDecorations.vue' /* webpackChunkName: "components/product-location-decorations" */).then(c => wrapFunctional(c.default || c))
export const ProductPrices = () => import('../../client/components/productView/ProductPrices.vue' /* webpackChunkName: "components/product-prices" */).then(c => wrapFunctional(c.default || c))
export const ProductShippingCalculator = () => import('../../client/components/productView/ProductShippingCalculator.vue' /* webpackChunkName: "components/product-shipping-calculator" */).then(c => wrapFunctional(c.default || c))
export const ProductView1 = () => import('../../client/components/productView/ProductView1.vue' /* webpackChunkName: "components/product-view1" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewed1 = () => import('../../client/components/productView/RecentlyViewed1.vue' /* webpackChunkName: "components/recently-viewed1" */).then(c => wrapFunctional(c.default || c))
export const Recommended = () => import('../../client/components/productView/Recommended.vue' /* webpackChunkName: "components/recommended" */).then(c => wrapFunctional(c.default || c))
export const SubPartsColors = () => import('../../client/components/productView/SubPartsColors.vue' /* webpackChunkName: "components/sub-parts-colors" */).then(c => wrapFunctional(c.default || c))
export const Products1 = () => import('../../client/components/products/Products1.vue' /* webpackChunkName: "components/products1" */).then(c => wrapFunctional(c.default || c))
export const LargeQuote1 = () => import('../../client/components/quote/LargeQuote1.vue' /* webpackChunkName: "components/large-quote1" */).then(c => wrapFunctional(c.default || c))
export const QuickQuote1 = () => import('../../client/components/quote/QuickQuote1.vue' /* webpackChunkName: "components/quick-quote1" */).then(c => wrapFunctional(c.default || c))
export const Quote1 = () => import('../../client/components/quote/Quote1.vue' /* webpackChunkName: "components/quote1" */).then(c => wrapFunctional(c.default || c))
export const ReadMore1 = () => import('../../client/components/read-more/ReadMore1.vue' /* webpackChunkName: "components/read-more1" */).then(c => wrapFunctional(c.default || c))
export const ReferenceField = () => import('../../client/components/referenceField/ReferenceField.vue' /* webpackChunkName: "components/reference-field" */).then(c => wrapFunctional(c.default || c))
export const ReferenceFieldCell = () => import('../../client/components/referenceField/ReferenceFieldCell.vue' /* webpackChunkName: "components/reference-field-cell" */).then(c => wrapFunctional(c.default || c))
export const ReorderProductItem = () => import('../../client/components/reorderView/ReorderProductItem.vue' /* webpackChunkName: "components/reorder-product-item" */).then(c => wrapFunctional(c.default || c))
export const ReorderView1 = () => import('../../client/components/reorderView/ReorderView1.vue' /* webpackChunkName: "components/reorder-view1" */).then(c => wrapFunctional(c.default || c))
export const ResetPassword1 = () => import('../../client/components/resetpassword/ResetPassword1.vue' /* webpackChunkName: "components/reset-password1" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../client/components/review/ProductRating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductRatingGraph = () => import('../../client/components/review/ProductRatingGraph.vue' /* webpackChunkName: "components/product-rating-graph" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewsList1 = () => import('../../client/components/review/ProductReviewsList1.vue' /* webpackChunkName: "components/product-reviews-list1" */).then(c => wrapFunctional(c.default || c))
export const ReviewForm = () => import('../../client/components/review/ReviewForm.vue' /* webpackChunkName: "components/review-form" */).then(c => wrapFunctional(c.default || c))
export const ReviewOrderProduct1 = () => import('../../client/components/review/ReviewOrderProduct1.vue' /* webpackChunkName: "components/review-order-product1" */).then(c => wrapFunctional(c.default || c))
export const ReviewPreview = () => import('../../client/components/review/ReviewPreview.vue' /* webpackChunkName: "components/review-preview" */).then(c => wrapFunctional(c.default || c))
export const ReviewsList = () => import('../../client/components/review/ReviewsList.vue' /* webpackChunkName: "components/reviews-list" */).then(c => wrapFunctional(c.default || c))
export const SampleRequest1 = () => import('../../client/components/sample/SampleRequest1.vue' /* webpackChunkName: "components/sample-request1" */).then(c => wrapFunctional(c.default || c))
export const SampleItemsAdded1 = () => import('../../client/components/sampleitemsadded/SampleItemsAdded1.vue' /* webpackChunkName: "components/sample-items-added1" */).then(c => wrapFunctional(c.default || c))
export const SearchBlogsResults1 = () => import('../../client/components/search/SearchBlogsResults1.vue' /* webpackChunkName: "components/search-blogs-results1" */).then(c => wrapFunctional(c.default || c))
export const SearchFacetsResults1 = () => import('../../client/components/search/SearchFacetsResults1.vue' /* webpackChunkName: "components/search-facets-results1" */).then(c => wrapFunctional(c.default || c))
export const SearchSuppliersResults1 = () => import('../../client/components/search/SearchSuppliersResults1.vue' /* webpackChunkName: "components/search-suppliers-results1" */).then(c => wrapFunctional(c.default || c))
export const SearchThemesResults1 = () => import('../../client/components/search/SearchThemesResults1.vue' /* webpackChunkName: "components/search-themes-results1" */).then(c => wrapFunctional(c.default || c))
export const Signin1 = () => import('../../client/components/signin/Signin1.vue' /* webpackChunkName: "components/signin1" */).then(c => wrapFunctional(c.default || c))
export const SigninWithSSO = () => import('../../client/components/signin/SigninWithSSO.vue' /* webpackChunkName: "components/signin-with-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const Signout1 = () => import('../../client/components/signout/Signout1.vue' /* webpackChunkName: "components/signout1" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../client/components/skeleton/index.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const SpecialCard = () => import('../../client/components/special/SpecialCard.vue' /* webpackChunkName: "components/special-card" */).then(c => wrapFunctional(c.default || c))
export const SpecialsList = () => import('../../client/components/special/SpecialsList.vue' /* webpackChunkName: "components/specials-list" */).then(c => wrapFunctional(c.default || c))
export const FacebookDelete1 = () => import('../../client/components/sso/FacebookDelete1.vue' /* webpackChunkName: "components/facebook-delete1" */).then(c => wrapFunctional(c.default || c))
export const SingleSignOn1 = () => import('../../client/components/sso/SingleSignOn1.vue' /* webpackChunkName: "components/single-sign-on1" */).then(c => wrapFunctional(c.default || c))
export const Supplier1 = () => import('../../client/components/supplier/Supplier1.vue' /* webpackChunkName: "components/supplier1" */).then(c => wrapFunctional(c.default || c))
export const TrackShipment1 = () => import('../../client/components/trackShipment/TrackShipment1.vue' /* webpackChunkName: "components/track-shipment1" */).then(c => wrapFunctional(c.default || c))
export const TrackShipmentArticle = () => import('../../client/components/trackShipment/TrackShipmentArticle.vue' /* webpackChunkName: "components/track-shipment-article" */).then(c => wrapFunctional(c.default || c))
export const TrackShipmentDetails = () => import('../../client/components/trackShipment/TrackShipmentDetails.vue' /* webpackChunkName: "components/track-shipment-details" */).then(c => wrapFunctional(c.default || c))
export const TrackShipmentItemInfo = () => import('../../client/components/trackShipment/TrackShipmentItemInfo.vue' /* webpackChunkName: "components/track-shipment-item-info" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearch = () => import('../../client/components/typeahead/GlobalSearch.vue' /* webpackChunkName: "components/global-search" */).then(c => wrapFunctional(c.default || c))
export const UploadArtworksList = () => import('../../client/components/uploadButton/UploadArtworksList.vue' /* webpackChunkName: "components/upload-artworks-list" */).then(c => wrapFunctional(c.default || c))
export const UploadButton = () => import('../../client/components/uploadButton/UploadButton.vue' /* webpackChunkName: "components/upload-button" */).then(c => wrapFunctional(c.default || c))
export const UploadFileListItem = () => import('../../client/components/uploadButton/UploadFileListItem.vue' /* webpackChunkName: "components/upload-file-list-item" */).then(c => wrapFunctional(c.default || c))
export const UploadFilesList = () => import('../../client/components/uploadButton/UploadFilesList.vue' /* webpackChunkName: "components/upload-files-list" */).then(c => wrapFunctional(c.default || c))
export const UploadFromLink = () => import('../../client/components/uploadButton/UploadFromLink.vue' /* webpackChunkName: "components/upload-from-link" */).then(c => wrapFunctional(c.default || c))
export const UploadModalButton = () => import('../../client/components/uploadButton/UploadModalButton.vue' /* webpackChunkName: "components/upload-modal-button" */).then(c => wrapFunctional(c.default || c))
export const CustomerArtworkProofs = () => import('../../client/components/account/Proofs/CustomerArtworkProofs.vue' /* webpackChunkName: "components/customer-artwork-proofs" */).then(c => wrapFunctional(c.default || c))
export const Proofs = () => import('../../client/components/account/Proofs/Index.vue' /* webpackChunkName: "components/proofs" */).then(c => wrapFunctional(c.default || c))
export const ProofsList = () => import('../../client/components/account/Proofs/ProofsList.vue' /* webpackChunkName: "components/proofs-list" */).then(c => wrapFunctional(c.default || c))
export const Artwork = () => import('../../client/components/account/admin/Artwork.vue' /* webpackChunkName: "components/artwork" */).then(c => wrapFunctional(c.default || c))
export const Customers = () => import('../../client/components/account/admin/Customers.vue' /* webpackChunkName: "components/customers" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../client/components/account/admin/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const BlogCategoriesList = () => import('../../client/components/blog/BlogPostList/BlogCategoriesList.vue' /* webpackChunkName: "components/blog-categories-list" */).then(c => wrapFunctional(c.default || c))
export const BlogPostCard = () => import('../../client/components/blog/BlogPostList/BlogPostCard.vue' /* webpackChunkName: "components/blog-post-card" */).then(c => wrapFunctional(c.default || c))
export const BlogPostList = () => import('../../client/components/blog/BlogPostList/index.vue' /* webpackChunkName: "components/blog-post-list" */).then(c => wrapFunctional(c.default || c))
export const Closeout = () => import('../../client/components/categories/closeout/index.vue' /* webpackChunkName: "components/closeout" */).then(c => wrapFunctional(c.default || c))
export const NoMinimum = () => import('../../client/components/categories/no-minimum/index.vue' /* webpackChunkName: "components/no-minimum" */).then(c => wrapFunctional(c.default || c))
export const ProductCheckInventory = () => import('../../client/components/productView/ProductInventory/ProductCheckInventory.vue' /* webpackChunkName: "components/product-check-inventory" */).then(c => wrapFunctional(c.default || c))
export const ProductInventoryItem = () => import('../../client/components/productView/ProductInventory/ProductInventoryItem.vue' /* webpackChunkName: "components/product-inventory-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInventoryLocation = () => import('../../client/components/productView/ProductInventory/ProductInventoryLocation.vue' /* webpackChunkName: "components/product-inventory-location" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaPreview = () => import('../../client/components/productView/media/ProductMediaPreview.vue' /* webpackChunkName: "components/product-media-preview" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaPreviewFile = () => import('../../client/components/productView/media/ProductMediaPreviewFile.vue' /* webpackChunkName: "components/product-media-preview-file" */).then(c => wrapFunctional(c.default || c))
export const CustomerArtworkProofView1 = () => import('../../client/components/proofs/proofView/CustomerArtworkProofView1.vue' /* webpackChunkName: "components/customer-artwork-proof-view1" */).then(c => wrapFunctional(c.default || c))
export const ProofView1 = () => import('../../client/components/proofs/proofView/ProofView1.vue' /* webpackChunkName: "components/proof-view1" */).then(c => wrapFunctional(c.default || c))
export const OrderApprovals = () => import('../../client/components/account/admin/OrderApprovals/index.vue' /* webpackChunkName: "components/order-approvals" */).then(c => wrapFunctional(c.default || c))
export const Reporting = () => import('../../client/components/account/admin/Reporting/index.vue' /* webpackChunkName: "components/reporting" */).then(c => wrapFunctional(c.default || c))
export const AddressInfo = () => import('../../client/components/account/admin/StoreAddresses/AddressInfo.vue' /* webpackChunkName: "components/address-info" */).then(c => wrapFunctional(c.default || c))
export const ImportShipping = () => import('../../client/components/account/admin/StoreAddresses/ImportShipping.vue' /* webpackChunkName: "components/import-shipping" */).then(c => wrapFunctional(c.default || c))
export const ShippingImportCSV = () => import('../../client/components/account/admin/StoreAddresses/ShippingImportCSV.vue' /* webpackChunkName: "components/shipping-import-c-s-v" */).then(c => wrapFunctional(c.default || c))
export const StoreAddressFormInput = () => import('../../client/components/account/admin/StoreAddresses/StoreAddressFormInput.vue' /* webpackChunkName: "components/store-address-form-input" */).then(c => wrapFunctional(c.default || c))
export const StoreAddressFormModal = () => import('../../client/components/account/admin/StoreAddresses/StoreAddressFormModal.vue' /* webpackChunkName: "components/store-address-form-modal" */).then(c => wrapFunctional(c.default || c))
export const StoreAddresses = () => import('../../client/components/account/admin/StoreAddresses/index.vue' /* webpackChunkName: "components/store-addresses" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../client/components/account/admin/Reporting/View/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const ReportingViewFormModal = () => import('../../client/components/account/admin/Reporting/View/ReportingViewFormModal.vue' /* webpackChunkName: "components/reporting-view-form-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
